
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import { Watch } from "vue-property-decorator";
    import router from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import { RegionSearchParameters } from "@/model/RegionSearchParameters";
    import { ILookupItem } from "@/model/LookupItem";
    import { Region, IRegion } from "@/model/Region";
    import utils from "@/stuff/Utils";
    import store from "@/store/store";
import { UserRole } from "@/model/Enums";

    @Component({
        components: { ApiButton }
    })
    export default class Regions extends Vue {

        async mounted() {
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;

            await Promise.all([
                this.loadBuyerList()
            ]);

            if (this.isBuyerZone) {
                this.buyerID = store.state.signedInUser == null ? 0 : +store.state.signedInUser.buyerID;
            } 
            else if (!this.isBuyerZone && store.state.signedInUser != null && store.state.signedInUser.role == UserRole.Buyer) {
                router.push("/");
            }
        }

        //
        // -- properties
        //

        private searchIsBusy: boolean = false;
        private searchParameters = new RegionSearchParameters();
        private totalCount = -1;

        private buyerList: Array<ILookupItem> = [];
        private buyerOptions: Array<ILookupItem> = [];

        private regionList: Array<Region> = [];

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private isBuyerZone: boolean = false;

        // params actually has an ARRAY of buyer IDs - so use this property to implement a single value
        // (also doubles as a watch on the property)
        get buyerID(): number {
            return this.searchParameters.buyerIDs.length === 0 ? 0 : this.searchParameters.buyerIDs[0];
        }
        set buyerID(value: number) {
            const was = this.buyerID;
            this.searchParameters.buyerIDs = value ? [value] :  [];
            if(was !== value) this.refreshSearch();
        }

        //
        // -- watchers
        //

        @Watch("searchParameters.showDeleted")
        onShowDeletedChanged() {
            this.refreshSearch();
        }

        // we can't user / don't need a watcher for buyerID 'cos we're using a setter

        //
        // -- methods
        //

        async edit(regionData: IRegion) {
            if (this.isBuyerZone) {
                router.push("/buyerZone/region/" + regionData.id);
            }
            else {
                router.push("/region/" + regionData.id);
            }
        }

        editNew() {
            router.push("/region/" + 0);
        }

        refreshSearch(event?: Event) {
            //utils.debounce(() => {
                this.regionList = [];
                this.searchParameters.pageNumber = 1;
                this.totalCount = -1;
                this.search(event);
            //}, 200);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.regionList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.regionList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            if (this.searchIsBusy) {
                console.log("Skipped search because busy...");
                return;
            }
            this.searchIsBusy = true;

            if (this.isBuyerZone) {
                this.searchParameters.buyerIDs = store.state.signedInUser == null ? [] : [+store.state.signedInUser.buyerID];
            }

            const response = await apiClient.post("/Api/Region/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.regionList.push(...response.list.map((r: IRegion) => new Region(r)));
            this.searchIsBusy = false;
        }

        async loadBuyerList() {
            this.buyerList = await apiClient.get("/api/buyer/lookups?supplierID=0");
            this.buyerOptions = utils.selectOptions(this.buyerList, "Any buyer");
        }
    }
